<template>
    <h5>Alta de Usuario</h5>

    <Form role="form" class="text-start mt-4" :validation-schema="schema" @submit="handleAltaUsuario">

        <soft-field id="username" type="text" v-model="user.username" :useLabel="true" label="Usuario" name="username" class="mb-2 label-input2" largo="30" />

        <soft-field id="nombre" type="text" v-model="user.name" :useLabel="true" label="Nombre" name="nombre" class="mb-2 label-input2" largo="80" />

        <soft-field id="email" type="email" v-model="user.email" :useLabel="true" label="Email" name="email" class="mb-2 label-input2" />

        <soft-select id="rolid" v-model="user.rolid" name="rolid" :valores="roles" :useLabel="true" label="Rol" class="mb-2 label-select2" />

        <soft-select v-if="miRol==1" id="tipsterid" v-model="user.tipsterid" name="tipsterid" :valores="tipsters" :useLabel="true" label="Tipster" class="mb-2 label-select2" />
  
        <soft-button class="float-end mt-3 px-5" color="tipster" :is-disabled="loading ? true : false">
            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
            <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Confirmar</span>
        </soft-button>
    </Form>
</template>
  
<script>
  import SoftField from "@/components/SoftField.vue";
  import SoftSelect from "@/components/SoftSelect.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "AltaUsuarioForm",
    components: {
        SoftField,
        SoftSelect,
        SoftButton,
        Form,
    },
    data() {
        const schema = yup.object().shape({
            email: yup.string().required("El email es necesario!").email("Debe indicar un email válido"),
            username: yup.string().required("El usuario es necesario!").max(30,'El tamaño máximo es de 30 caracteres').matches(/^[a-zA-Z0-9.-]+$/, 'El usuario contiene caracteres no permitidos'),
            nombre: yup.string().required("El nombre es necesario!").max(80,'El tamaño máximo es de 80 caracteres'),
            rolid: yup.number().positive('Debe indicar un rol'),
        /*  tipsterid: yup.number().test('is-valid-tipsterid', 'Tipster ID es requerido cuando rolId > 1', function (value) {
                const { rolid } = this.parent;
                console.log('rolid:', rolid, 'tipsterid:', this.parent);
                // Si rolId es mayor que 1, tipsterid debe ser informado
                if (rolid > 1) {
                    return value > 0; // tipsterid debe ser mayor que 0
                }
                // En otro caso, tipsterid debe valer 0
                return value === 0;
            }),*/
        });

        const miRol = localStorage.getItem("usuarioRolId");
        var roles = [ {id:1,nombre:'Administrador'}, {id:2,nombre:'Admin.Tipster'}, {id:3,nombre:'Cliente'}];
        if (miRol==2) {
            roles = [ {id:2,nombre:'Admin.Tipster'}, {id:3,nombre:'Cliente'}];
        }

        return {
            loading: false,
            user:{
                email: '',
                username: '',
                name: '',
                rolid: null,
                tipsterid: miRol==2 ? localStorage.getItem("usuarioTipsterId") : null
            },
            schema,
            roles: roles,
            tipsters: [],
            miRol: miRol
        };
    },
    emits: ['cerrar-modal'],
    methods: {
      async handleAltaUsuario() {
        // Aquí puedes realizar acciones cuando se envía el formulario
        console.log('Formulario enviado:', this.user);
        this.loading = true;
        try {
            this.loading = false;
            const resp = await this.$store.dispatch("auth/guardaUsuario", this.user);
            if (resp.res=='OK') {
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Se ha registrado el nuevo usuario",
                    width: 600,
                });
                this.$emit('cerrar-modal');
            }
            else {
                showSwal.methods.showSwal({
                    type: "error",
                    message: resp.mensaje,
                    width: 500
                });               
            }
        } catch (error) {
            this.loading = false;
            showSwal.methods.showSwal({
                type: "error",
                message: "Se ha producido un error",
                width: 500,
            });
        }
      },
    },

    async mounted() { 
        this.tipsters = await this.$store.dispatch("auth/listaTipsters");
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  </style>