<template>
  <div class="mt-4 px-4 container-fluid">
    <div class="breadcrumb text-md px-0 mb-4">
      <p><span class="breadcrumb-item" @click="irInicio">Inicio</span> > Mi perfil</p>
    </div>
  </div>

  <div class="px-4 container-fluid">
    <div id="profile" class="card card-body mt-4 mb-4">
      <div v-if="profile" class="row justify-content-left align-items-left">
        <div class="col-sm-auto col-4">
          <soft-avatar
            :img="pic"
            alt="profile"
            size="xl"
            shadow="sm"
            border-radius="lg"
          />
        </div>
        <div class="col-sm-auto col-8 my-auto">
          <div class="h-100">
            <h5 class="mb-1 font-weight-bolder">{{ profile.name }}</h5>
            <p class="mb-0 font-weight-bold text-sm">{{ profile.email }}</p>
          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div id="basic-info" class="card mt-4 mb-5">
          <div class="card-header">
            <h5>Información básica</h5>
          </div>

          <div class="card-body pt-0">
            <div class="row mb-3">
              <soft-field id="name" type="text" :useLabel="true" label="Nombre" name="name" v-model="profileChange.name" class="mb-2 label-input" largo="50"/>
              <validation-error :errors="apiValidationErrors.name" />
            </div>

            <div class="row mb-3">
              <soft-field id="email" type="text" :useLabel="true" label="Email" name="email" v-model="profileChange.email" class="mb-2 label-input" largo="100"/>
              <validation-error :errors="apiValidationErrors.email" />
            </div>

            <soft-button style="min-width:150px;" class="float-end mt-3 mb-0" color="tipster" :is-disabled="loading2 ? true : false" @click="handleProfileChange">
              <span v-if="loading2" class="spinner-border spinner-border-sm"></span>
              <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important;">Actualizar</span>
            </soft-button>
          </div>
        </div>

        <div id="password" class="card mt-4">
          <div class="card-header">
            <h5>Cambio de clave</h5>
          </div>

          <div class="card-body pt-0">
            <div class="row mb-3">
              <soft-field id="newPassword" type="password" :useLabel="true" label="Nueva clave" name="newPassword" v-model="passChange.password" class="mb-2 label-input" largo="100"/>
              <validation-error :errors="apiValidationErrors.password" />
            </div>

            <div class="row mb-3">
              <soft-field id="confirmPassword" type="password" :useLabel="true" label="Confirma nueva clave" name="confirmPassword" v-model="passChange.password_confirmation" class="mb-2 label-input" largo="100"/>
            </div>

            <soft-button style="min-width:180px;" color="tipster" class="float-end mt-3 mb-0" :is-disabled="loading ? true : false" @click="handlePassChange">
              <span v-if="loading" class="spinner-border spinner-border-sm"></span>
              <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important;">Cambiar clave</span>
            </soft-button>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div id="datos-tipster" class="card mt-4" v-if="profile&&profile.rol_id==2">
          <div class="card-header">
            <h5>Datos del tipster</h5>
          </div>

          <div class="card-body pt-0">
            <div class="row mb-3">
              <soft-field id="nombreTipster" type="text" :useLabel="true" label="Nombre" name="nombre" v-model="tipsterChange.nombre" class="mb-2 label-input" largo="50"/>
              <validation-error :errors="apiValidationErrors.nombre" />
            </div>

            <div class="row mb-3">
              <soft-field id="contactoTipster" type="text" :useLabel="true" label="Contacto" name="contacto" v-model="tipsterChange.contacto" class="mb-2 label-input" largo="80"/>
              <validation-error :errors="apiValidationErrors.contacto" />
            </div>

            <div class="row mb-3">
              <soft-field id="emailTipster" type="text" :useLabel="true" label="Email" name="email" v-model="tipsterChange.email" class="mb-2 label-input" largo="80"/>
              <validation-error :errors="apiValidationErrors.email" />
            </div>

            <div class="row mb-3" style="position:relative;">
              <soft-field id="telefonoTipster" type="text" :useLabel="true" label="Teléfono" name="telefono" v-model="tipsterChange.telefono" class="mb-2 label-input" largo="50"/>
              <validation-error :errors="apiValidationErrors.telefono" />
              <soft-button style="min-width:180px;" color="tipster" class="float-end mt-3 mb-0" id="boton-telefono" :is-disabled="loading ? true : false" @click="handleValidaTelefono" v-if="tipsterChange.movilValidado!='1'">
                <span class="text-sm" style="font-weight:normal;font-size:0.9rem!important;">Validar</span>
              </soft-button>
            </div>

            <p v-if="tipsterChange.movilValidado!='1'">Debe validar su teléfono en Telegram para obtener pleno control sobre sus canales y obtener estadísticas</p>

            <soft-button style="min-width:180px;" color="tipster" class="float-end mt-3 mb-0" :is-disabled="loading3 ? true : false" @click="handleChangeTipster">
              <span v-if="loading3" class="spinner-border spinner-border-sm"></span>
              <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important;">Cambiar datos</span>
            </soft-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal @cerrar-modal="cerrarModal" :modalActive="modalActive">
    <div class="modal-content">
      <usuario-validado-form @cerrar-modal="(resp) => { modalActive=false;}" :key="tipsterChange.id" :telefono="tipsterChange.telefono" :tipsterId="tipsterChange.id.toString()"/>
    </div>
  </Modal>

</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftField from "@/components/SoftField.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "/src/mixins/form-mixin.js";
import img from "/src/assets/img/bruce-mars.jpg";
import showSwal from "/src/mixins/showSwal.js";
import Modal from './components/Modal.vue';
import UsuarioValidadoForm from './components/UsuarioValidadoForm.vue';

export default {
  name: "Profile",
  components: {
    Modal,
    SoftButton,
    SoftAvatar,
    SoftField,
    ValidationError,
    UsuarioValidadoForm,
  },
  mixins: [formMixin],
  data() {
    const passChange = {
      password: "",
      password_confirmation: "",
    };
    const profileChange = {
      name: "",
      email: "",
      profile_image: "",
    };
    const tipsterChange = {
      id: 0,
      nombre: "",
      contacto: "",
      email: "",
      telefono: "",
      indvalidado: ""
    };
    return {
      modalActive: false,
      passChange,
      profileChange,
      tipsterChange,
      loading: false,
      loading2: false,
      loading3: false,
      img,
    };
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
    pic() {
      if (this.profile.profile_image) return this.profile.profile_image;
      else return require("/src/assets/img/placeholder.jpg");
    },
  },
  async created() {
    try {
      await this.$store.dispatch("profile/getProfile");
      this.profileChange = { ...this.profile };
      this.tipsterChange = { ...this.profile.tipster}; 
    } catch (error) {
      try {
        await this.$store.dispatch("auth/logout");
      } finally {
        this.$router.push("/login");
      }
    }
  },
  methods: {
    async handlePassChange() {

      this.resetApiValidation();
      this.loading = true;

      try {
        await this.$store.dispatch("profile/updateProfile", this.passChange);
        showSwal.methods.showSwal({
          type: "success",
          message: "Su clave ha sido cambiada",
          width: 500,
        });
        this.passChange.password = "";
        this.passChange.password_confirmation = "";
        this.loading = false;
      } catch (error) {
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async handleProfileChange() {

      this.resetApiValidation();
      this.loading2 = true;

      try {
        await this.$store.dispatch("profile/updateProfile", this.profileChange);
        showSwal.methods.showSwal({
          type: "success",
          message: "Su cambio de información ha sido registrado!",
          width: 600,
        });
        this.loading2 = false;
      } catch (error) {
        console.log(error);
        this.setApiValidation(error.response.data.errors);
        this.loading2 = false;
      }
    },

    async handleValidaTelefono() {
      this.modalActive = true;
    },
    
    async handleChangeTipster() {

      this.resetApiValidation();
      this.loading3 = true;

      try {
        const resp = await this.$store.dispatch("profile/updateTipster", this.tipsterChange);
        showSwal.methods.showSwal({
          type: resp.res=='OK' ? "success" : "error",
          message: resp.res=='OK' ? "Datos guardados correctamente" : resp.error,
          width: 600,
        });

        this.loading3 = false;
      } catch (error) {
        console.log(error);
      //  this.setApiValidation(error.response.data.errors);
        this.loading3 = false;
      }
    },
    
    cerrarModal() {
      this.modalActive = false;
    },
  },
};
</script>
<style>
img {
  object-fit: cover;
}
#telefonoTipster {
  width: calc(100% - 270px);
  margin-right: 106px;
}
#boton-telefono {
  min-width:initial!important;
  width: 90px;
    position: absolute;
    right: 12px;
    top: -10px;
    padding: 3px 0;
}
</style>
