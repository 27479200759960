<template>
    <h5>Modificar Usuario</h5>
    <Form role="form" class="text-start mt-4" :validation-schema="schema" @submit="handleEditaUsuario">

        <soft-field id="username" type="text" v-model="usuarioEditusername" :useLabel="true" label="Usuario" name="username" class="mb-2 label-input2" largo="30"/>

        <soft-field id="nombre" type="text" v-model="usuarioEditName" :useLabel="true" label="Nombre" name="nombre" class="mb-2 label-input2" largo="80"/>

        <soft-field id="email" type="email" v-model="usuarioEditemail" :useLabel="true" label="Email" name="email" class="mb-2 label-input2"/>

        <soft-select id="rolid" v-model="usuarioEditrol" name="rolid" :valores="roles" :useLabel="true" label="Rol" class="mb-2 label-select2"/>

        <soft-select v-if="miRol==1" id="tipsterid" v-model="usuarioEditTipster" name="tipsterid" :valores="tipsters" :useLabel="true" label="Tipster" class="mb-2 label-select2"/>

        <soft-button class="float-end mt-3 px-5" color="tipster" :is-disabled="loading ? true : false">
            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
            <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Confirmar</span>
        </soft-button>
    </Form>
  </template>
  
  <script>
  import SoftField from "@/components/SoftField.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import SoftSelect from "@/components/SoftSelect.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "EditaUsuarioForm",
    components: {
        SoftField,
        SoftButton,
        SoftSelect,
        Form,
    },
    props: {
        usuarioId: Number,
        usuarioName: String,
        usuarioUsername: String,
        usuarioEmail: String,
        usuarioRolId: String,
        usuarioTipsterId: String
    },
    data() {
        const schema = yup.object().shape({
            email: yup.string().required("El email es necesario!").email("Debe indicar un email válido"),
            username: yup.string().required("El usuario es necesario!").max(30,'El tamaño máximo es de 30 caracteres').matches(/^[a-zA-Z0-9.-]+$/, 'El usuario contiene caracteres no permitidos'),
            nombre: yup.string().required("El nombre es necesario!").max(80,'El tamaño máximo es de 80 caracteres'),
            rolid: yup.number().positive('Debe indicar un rol')
        });

        const miRol = localStorage.getItem("usuarioRolId");
        var roles = [ {id:1,nombre:'Administrador'}, {id:2,nombre:'Admin.Tipster'}, {id:3,nombre:'Cliente'}];
        if (miRol==2) {
            roles = [ {id:2,nombre:'Admin.Tipster'}, {id:3,nombre:'Cliente'}];
        }

        return {
            loading: false,
            schema,
            roles: roles,
            tipsters: [],
            usuarioEditId: this.usuarioId,
            usuarioEditName: this.usuarioName,
            usuarioEditusername: this.usuarioUsername,
            usuarioEditemail: this.usuarioEmail,
            usuarioEditrol: this.usuarioRolId,
            usuarioEditTipster: this.usuarioTipsterId,
            miRol: miRol
        };
    },
    emits: ['cerrar-modal'],
    methods: {
        async handleEditaUsuario() {    console.log('handleEditaUsuario');
            // Aquí puedes realizar acciones cuando se envía el formulario
            this.loading = true;
            try { 
                let user = {};
                user['id'] = this.usuarioId; 
                user['name'] = this.usuarioEditName; 
                user['username'] = this.usuarioEditusername; 
                user['email'] = this.usuarioEditemail; 
                user['rolid'] = this.usuarioEditrol; 
                user['tipsterid'] = this.usuarioEditTipster;
                
                this.loading = false;
                const resp = await this.$store.dispatch("auth/guardaUsuario", user);
                if (resp.res=='OK') {
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Se ha actualizado el usuario",
                        width: 600,
                    });
                    this.$emit('cerrar-modal');
                }
                else {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: resp.mensaje,
                        width: 500
                    });               
                }
            } catch (error) {
                this.loading = false;
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Se ha producido un error",
                    width: 500,
                });
            }
        },
    },

    async mounted() { 
        this.tipsters = await this.$store.dispatch("auth/listaTipsters");
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  </style>