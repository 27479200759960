<template>
  <div class="mt-4 px-4 container-fluid">
    <div class="breadcrumb text-md px-0 mb-4">
      <p><span class="breadcrumb-item" @click="irInicio">Inicio</span> > Configuración</p>
    </div>
  </div>
  
  <div class="px-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div id="basic-info" class="card mt-4">
          <div class="card-header">
            <h5>Configuración de parámetros Tipsterfy</h5>
          </div>

          <Form role="form" class="text-start" @submit="handleConfiguracion">
            <div class="card-body pt-0">
              <fieldset class="px-4 pt-5">
                <legend>Información del BOT</legend>

                <div class="row mt-2 mb-3">
                  <soft-field id="bot" type="text" :useLabel="true" label="Nombre" name="bot" v-model="user.bot" class="mb-2 label-input" largo="50"/>
                </div>

                <div class="row mb-3">
                  <soft-field id="tokenBot" type="text" :useLabel="true" label="Token" name="tokenBot" v-model="user.tokenBot" class="mb-2 label-input" largo="150"/>
                </div>

                <div class="row mb-3 mt-3 px-4">
                  <soft-switch id="canales-switch" name="canales" @change="cambiarCanales" :checked="user.mostrarCanales"
                      :label="'Activar el rastreo de canales (Último rastreo ' + rastreoTelegram + ')'"/>
                </div>
              </fieldset>

              <fieldset class="px-4 pt-5 mt-6">
                <legend>Información API TdLib</legend>

                <div class="row mt-2 mb-3">
                  <soft-field id="apiId" type="text" :useLabel="true" label="API ID" name="apiId" v-model="user.apiId" class="mb-2 label-input" largo="50"/>
                </div>

                <div class="row mb-3">
                  <soft-field id="apiHash" type="text" :useLabel="true" label="API HASH" name="apiHash" v-model="user.apiHash" class="mb-2 label-input" largo="150"/>
                </div>
              </fieldset>

              <soft-button style="min-width:150px;" class="float-end mt-5 mb-5 px-5" color="tipster" :is-disabled="loading ? true : false">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Confirmar</span>
              </soft-button>

            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import showSwal from "@/mixins/showSwal.js";
import SoftField from "@/components/SoftField.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import {globalFunctionsMixin} from '@/mixins/globalFunctions.js';
import { Form } from "vee-validate";

export default {
  name: "Configuracion",
  components: {
    SoftField,
    SoftButton,
    SoftSwitch,
    Form
  },
  mixins: [globalFunctionsMixin],
  data() {
    return {
      miRol: localStorage.getItem("usuarioRolId"),
      loading: false,
      user:{
        bot: '',
        tokenBot: '',
        apiId: '',
        apiHash: '',
        mostrarCanales: false
      },
      rastreoTelegram: ''
    };
  },
  computed: {
  },
  methods: {
    alert() {
      showSwal.methods.showSwal({
        type: "error",
        message: "This is a PRO feature.",
        width: 400,
      });
    },

    async handleConfiguracion() {
      console.log('Formulario enviado:', this.user);
        this.loading = true;
        try {
            const resp = await this.$store.dispatch("auth/guardaConfiguracion", this.user);
            this.loading = false;
            if (resp.res=='OK') {
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Se ha guardado la configuración",
                    width: 600,
                });
                this.$emit('cerrar-modal');
            }
            else {
                showSwal.methods.showSwal({
                    type: "error",
                    message: resp.mensaje,
                    width: 500
                });               
            }
        } catch (error) {
            this.loading = false;
            showSwal.methods.showSwal({
                type: "error",
                message: "Se ha producido un error",
                width: 500,
            });
        }
    },
    async cambiarCanales() {
      this.user.mostrarCanales=!this.user.mostrarCanales; 
    },
    irInicio() {
      this.$router.push('/dashboard');
    }
  },

  async mounted() { 
    this.$store.state.showFooter = true;

    const response = await this.$store.dispatch("auth/configuracion", {});
    if (response.data.length>0) {
      for (let row of response.data) {
        if (row.nombre=='bot') {
          this.user.bot = row.valor;
        }
        if (row.nombre=='tokenBot') {
          this.user.tokenBot = row.valor;
        }
        if (row.nombre=='apiId') {
          this.user.apiId = row.valor;
        }
        if (row.nombre=='apiHash') {
          this.user.apiHash = row.valor;
        }
        if (row.nombre=='mostrarCanales') {
          this.user.mostrarCanales = row.valor==1;
        }
        if (row.nombre=='rastreoTelegram') {
          this.rastreoTelegram = row.valor;
        }
      }
    }
  },

};
</script>

<style lang="scss" scoped>

</style>
