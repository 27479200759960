<template>
    <h5>Alta de campaña</h5>
    <Form role="form" class="text-start mt-4" :validation-schema="schema" @submit="handleAltaCampana">

        <soft-field type="text" id="nombre" v-model="campana.nombre" :useLabel="true" label="Nombre" name="nombre" class="mb-2 label-input2" largo="80"/>

        <soft-select v-if="miRol==1" id="tipsterid" v-model="tipsterid" name="tipsterid" :valores="tipsters" :useLabel="true" label="Seleccione un tipster" class="mb-2 label-select2" />

        <soft-select v-if="miRol>1 || tipsterid>0" id="canalid" v-model="campana.canalid" name="canalid" :valores="canales" :useLabel="true" label="Canal" class="mb-2 label-select2" />

        <soft-button class="float-end mt-3 px-5" color="tipster" :is-disabled="loading ? true : false">
            <span v-if="loading" class="spinner-border spinner-border-sm" ></span>
            <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Confirmar</span>
        </soft-button>
    </Form>
</template>
  
<script>
  import SoftField from "@/components/SoftField.vue";
  import SoftSelect from "@/components/SoftSelect.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "AltaCampanaForm",
    components: {
        SoftField,
        SoftSelect,
        SoftButton,
        Form,
    },
    props: {
        campanaBotName: String,
    },
    data() {
        const schema = yup.object().shape({
            nombre: yup.string().required("El nombre es necesario!").max(80,'El tamaño máximo es de 80 caracteres'),
        });

        const miRol = localStorage.getItem("usuarioRolId");
        var roles = [ {id:1,nombre:'Administrador'}, {id:2,nombre:'Admin.Tipster'}, {id:3,nombre:'Cliente'}];
        if (miRol==2) {
            roles = [ {id:2,nombre:'Admin.Tipster'}, {id:3,nombre:'Cliente'}];
        }

        return {
            loading: false,
            tipsterid: miRol==2 ? localStorage.getItem("usuarioTipsterId") : null,
            campana:{
                nombre: '',
                tipsterid: '',
            },
            schema,
            roles: roles,
            tipsters: [],
            miRol: miRol,
            canales: []
        };
    },
    watch: {
        tipsterid(newVal, oldVal) {
            console.log("tipsterid cambió de", oldVal, "a", newVal);
            this.obtenerCanales();
        },
    },
    emits: ['cerrar-modal'],
    methods: {
        async obtenerCanales() {
            this.canales = await this.$store.dispatch("auth/listaCanales",{tipsterId: this.tipsterid });
        },
        async handleAltaCampana() {
            // Aquí puedes realizar acciones cuando se envía el formulario
            this.campana.tipsterid = this.tipsterid;
            console.log('Formulario enviado:', this.campana);
            this.loading = true;
            try {
                const resp = await this.$store.dispatch("auth/guardaCampana", this.campana);
                this.loading = false;
                if (resp.res=='OK') {
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Se ha registrado la nueva campaña",
                        width: 600,
                        timer: 10000
                    });
                    this.$emit('cerrar-modal');
                }
                else {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: resp.mensaje,
                        width: 500
                    });               
                }
            } catch (error) {
                this.loading = false;
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Se ha producido un error",
                    width: 500,
                });
            }
        },
    },
    async mounted() { 
        this.tipsters = await this.$store.dispatch("auth/listaTipsters");
        this.obtenerCanales();
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  </style>