<template>
    <h5>Modificar Tipster</h5>

    <Form role="form" class="text-start mt-4" :validation-schema="schema" @submit="handleEditaTipster">

        <soft-field id="nombre" type="text" :useLabel="true" label="Nombre" name="nombre" v-model="tipsterEditNombre" class="mb-2 label-input2" largo="50"/>

        <soft-field id="contacto" type="text" :useLabel="true" label="Contacto" name="contacto" v-model="tipsterEditContacto" class="mb-2 label-input2" largo="80"/>

        <soft-field id="email" type="email" :useLabel="true" label="Email" name="email" v-model="tipsterEditEmail" class="mb-2 label-input2" largo="80"/>

        <soft-field id="telefono" type="tel" :useLabel="true" label="Teléfono" name="telefono" v-model="tipsterEditTelefono" class="mb-2 label-input2" largo="20"/>

        <soft-button class="float-end mt-3 px-5" color="tipster" :is-disabled="loading ? true : false">
            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
            <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Confirmar</span>
        </soft-button>

    </Form>
  </template>
  
  <script>
  import SoftField from "@/components/SoftField.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "EditaTipsterForm",
    components: {
        SoftField,
        SoftButton,
        Form,
    },
    props: {
        tipsterId: Number,
        tipsterName: String,
        tipsterEmail: String,
        tipsterContacto: String,
        tipsterTelefono: String,
    },
    data() {
        const schema = yup.object().shape({
            nombre: yup.string().required("El nombre es necesario!").max(50,'El tamaño máximo es de 50 caracteres'),
            contacto: yup.string().required("El contacto es necesario!").max(50,'El tamaño máximo es de 80 caracteres'),
            email: yup.string().email('El email no tiene un formato válido').required("El email es necesario!").max(80,'El tamaño máximo es de 80 caracteres'),
            telefono: yup.string().matches(/^\+[0-9]+$/, 'El teléfono debe estar en formato internacional y comenzar por +').max(20,'El tamaño máximo es de 20 caracteres'),
        });
        //console.log('Tengo ' + this.tipsterId + ' ' + this.tipsterRolId);
        return {
            loading: false,
            schema,
            tipsterEditId: this.tipsterId,
            tipsterEditNombre: this.tipsterName,
            tipsterEditEmail: this.tipsterEmail,
            tipsterEditContacto: this.tipsterContacto,
            tipsterEditTelefono: this.tipsterTelefono,
        };
    },
    emits: ['cerrar-modal'],
    methods: {
        async handleEditaTipster() {
            // Aquí puedes realizar acciones cuando se envía el formulario
            this.loading = true;
            try { 
                let user = {};
                user['id']       = this.tipsterEditId; 
                user['nombre']   = this.tipsterEditNombre; 
                user['email']    = this.tipsterEditEmail; 
                user['contacto'] = this.tipsterEditContacto; 
                user['telefono'] = this.tipsterEditTelefono; 
                
                this.loading = false;
                const resp = await this.$store.dispatch("auth/guardaTipster", user);
                if (resp.res=='OK') {
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Se ha actualizado el tipster",
                        width: 600,
                    });
                    this.$emit('cerrar-modal');
                }
                else {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: resp.mensaje,
                        width: 500
                    });               
                }
            } catch (error) {
                this.loading = false;
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Se ha producido un error",
                    width: 500,
                });
            }
        },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  </style>