<template>
    <h5>Alta de producto</h5>

    <Form role="form" class="text-start mt-4" :validation-schema="schema" @submit="handleAltaProducto">

        <soft-field type="text" id="nombre" v-model="user.nombre" :useLabel="true" label="Nombre" name="nombre" class="mb-2 label-input2" largo="50"/>

        <soft-field type="url" id="enlace" v-model="user.enlace" :useLabel="true" label="Enlace" name="enlace" class="mb-2 label-input2" largo="120"/>

        <soft-field type="number" id="precio" v-model="user.precio" :useLabel="true" label="Precio" name="precio" class="mb-2 label-input2"/>

        <soft-select v-if="miRol==1" id="tipsterid" v-model="user.tipsterid" name="tipsterid" :valores="tipsters" :useLabel="true" label="Tipster" class="mb-2 label-select2"/>
  
        <div class="mt-3">
            <soft-switch id="activo" name="activo" @change="cambiarActivado()" style="display:inline-block;" :checked=user.activo />
            <label for="enviar-email" style="font-size:0.85rem;margin:0;">Producto activo</label>
        </div>

        <soft-button class="float-end mt-3 px-5" color="tipster" :is-disabled="loading ? true : false">
            <span v-if="loading" class="spinner-border spinner-border-sm" ></span>
            <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Confirmar</span>
        </soft-button>
    </Form>
  </template>
  
  <script>
  import SoftField from "@/components/SoftField.vue";
  import SoftSelect from "@/components/SoftSelect.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import SoftSwitch from "@/components/SoftSwitch.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "AltaProductoForm",
    components: {
        SoftField,
        SoftSelect,
        SoftButton,
        SoftSwitch,
        Form,
    },
    data() {
        const schema = yup.object().shape({
            enlace: yup.string().required("El enlace es necesario!").url("Debe indicar un enlace válido"),
            precio: yup.number().required("El precio es necesario!").typeError("El precio debe ser un número válido").positive("El precio debe ser positivo").test("is-decimal", "El precio debe tener como máximo 2 decimales", (value) => {
                if (value === undefined || value === null) return true; // Permitir que Yup maneje `required`
                return Number(value.toFixed(2)) === value;
            }),
            nombre: yup.string().required("El nombre es necesario!").max(50,'El tamaño máximo es de 80 caracteres'),
        });
        
        const miRol = localStorage.getItem("usuarioRolId");
        
        return {
            loading: false,
            user:{
                nombre: '',
                enlace: '',
                precio: '0',
                tipsterid: miRol==2 ? localStorage.getItem("usuarioTipsterId") : null,
                activo: false,
            },
            schema,
            tipsters: [],
            miRol: miRol
        };
    },
    emits: ['cerrar-modal'],
    methods: {
        async handleAltaProducto() {
            // Aquí puedes realizar acciones cuando se envía el formulario
            console.log('Formulario enviado:');
            this.loading = true;
            try {
                this.loading = false;
                const resp = await this.$store.dispatch("auth/guardaProducto", this.user);
                if (resp.res=='OK') {
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Se ha registrado el nuevo usuario",
                        width: 600,
                    });
                    this.$emit('cerrar-modal');
                }
                else {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: resp.mensaje,
                        width: 500
                    });               
                }
            } catch (error) {
                this.loading = false;
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Se ha producido un error",
                    width: 500,
                });
            }
        },
        cambiarActivado() { 
            this.user.activo = !this.user.activo;
        }
    },
    async mounted() { 
        this.tipsters = await this.$store.dispatch("auth/listaTipsters");
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  </style>