<template>
    <h5>Móvil no validado</h5>

    <p v-if="paso==1">Tu móvil {{ telefono }} no está validado en Telegram, deberás hacerlo para obtener estadísticas de la actividad de tus canales</p>

    <Form role="form" class="text-start">
        <soft-button class="float-end mt-3 px-5" color="tipster" :is-disabled="loading ? true : false" @click="handleIniciaValidacion" v-if="paso==1">
            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
            <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Iniciar Validación</span>
        </soft-button>
    </Form>

    <Form role="form" class="text-start" :validation-schema="schema" @submit="handleValidarCodigo" v-if="paso==2">
        <p>En un momento recibirás un mensaje de Telegram con un código autorizando la monitorización de la cuenta</p>
        <soft-field id="codigo" v-model="codigo" type="number" placeholder="Indique código" name="codigo" class="mb-2" largo="10"/>
        <soft-button class="float-end mt-3 px-5" color="tipster" :is-disabled="loading ? true : false">
            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
            <span v-else class="text-sm" style="font-weight:normal;font-size:1.1rem!important">Validar código</span>
        </soft-button>
    </Form>

    <p v-if="paso==3">Comprobando código</p>

  </template>
  
  <script>
  import SoftField from "@/components/SoftField.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "UsuarioValidadoForm",
    components: {
        SoftField,
        SoftButton,
        Form,
    },
    props: {
        telefono: String,
        tipsterId: String
    },
    data() {
        const schema = yup.object().shape({
            codigo: yup.number()
                        .transform((value, originalValue) => (originalValue ? Number(originalValue) : NaN))
                        .typeError("El código debe ser un número").integer("El código debe ser un número entero")
                        .min(0, "El código debe ser mayor o igual a 0")
                        .max(999999999, "El código no puede ser mayor a 999999999")
                        .required("El código es necesario!"),
        });

        return {
            loading: false,
            paso: 1,
            codigo: '',
            schema,
        };
    },
    emits: ['cerrar-modal'],
    methods: {
        async handleIniciaValidacion() {    console.log('handleIniciaValidacion',this.telefono,this.tipsterId);
            // Aquí puedes realizar acciones cuando se envía el formulario
            this.loading = true;
            try { 
                const resp = await this.$store.dispatch("auth/validarMovilTipster", {telefono: this.telefono, tipsterId: this.tipsterId});
                this.loading = false;
                if (resp.res=='OK') {
                    this.paso = 2;
                }
                else {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: resp.mensaje,
                        width: 500
                    });               
                }
            } catch (error) {
                this.loading = false;
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Se ha producido un error",
                    width: 500,
                });
            }
        },

        async handleValidarCodigo() {    console.log('handleValidarCodigo');
            // Aquí puedes realizar acciones cuando se envía el formulario
            this.loading = true;
            try { 
                const resp = await this.$store.dispatch("auth/codigoMovilTipster", {telefono: this.telefono, tipsterId: this.tipsterId, codigo: this.codigo});
                this.loading = false;
                if (resp.res=='OK') {
                    this.paso = 3;
                    this.consultarCodigo();
                }
                else {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: resp.mensaje,
                        width: 500
                    });               
                }
            } catch (error) {
                this.loading = false;
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Se ha producido un error",
                    width: 500,
                });
            }
        },

        async consultarCodigo() {    console.log('consultarCodigo');
            // Aquí puedes realizar acciones cuando se envía el formulario
            try { 
                const resp = await this.$store.dispatch("auth/validaCodigoTipster", {telefono: this.telefono, tipsterId: this.tipsterId});
                this.loading = false;
                if (resp.res=='OK') {
                    if (resp.estado=='V') {
                        showSwal.methods.showSwal({
                            type: "success",
                            message: "Código Validado",
                            width: 400,
                        });
                    } 
                    else if (resp.estado=='E') {
                        this.paso = 2;
                        showSwal.methods.showSwal({
                            type: "error",
                            message: "Código erróneo",
                            width: 500
                        });    
                    }
                    else {
                        setTimeout(this.consultarCodigo, 5000);
                    }
                }
                else {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: resp.mensaje,
                        width: 500
                    });               
                }
            } catch (error) {
                this.loading = false;
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Se ha producido un error",
                    width: 500,
                });
            }
        },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  </style>