import authService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("userF"));
//const initialState = user ? { loggedIn: true } : { loggedIn: false };

export const auth = {
  namespaced: true,
  state: {
    loggedIn: user ? true : false
  },
  actions: {
    async login({ commit }, userData) {
      await authService.login(userData);
      commit("logMeIn");
    },

    async logout({ commit }) {
      try {
        await authService.logout();
      } finally {
        commit("logMeOut");
      }
    },

    async register(context, userData) {
      return await authService.register(userData);
    },

    async claveOlvidada(context, userData) {
      return await authService.claveOlvidada(userData);
    },

    async codigoRecuperado(context, userData) {
      return await authService.codigoRecuperado(userData);
    },

    async validaCodigo({ commit }, userData) {
      const resp = await authService.validaCodigo(userData);
      if (resp.data['res']=='OK') {  
        commit("logMeIn");    // Conectado
      }
    },

    async establecerClave(context, userData) {
      return await authService.establecerClave(userData);
    },

    async detallePedido(context, pedido) {
      return await authService.detallePedido(pedido);
    },
    
    async generaPedido(context, pedido) {
      return await authService.generaPedido(pedido);
    },

    async confirmarPagado(context, pedido) {
      return await authService.confirmarPagado(pedido);
    },

    // TIPSTERS
    async tipsters(context,tipster) {
      return await authService.tipsters(tipster);
    },

    async listaTipsters() {
      return await authService.listaTipsters();
    },

    async guardaTipster(context, tipster) { 
      return await authService.guardaTipster(tipster);
    },

    async borraTipster(context, tipster) { 
      return await authService.borraTipster(tipster);
    },

    async validarMovilTipster(context, tipster) { 
      return await authService.validarMovilTipster(tipster);
    },

    async codigoMovilTipster(context, tipster) { 
      return await authService.codigoMovilTipster(tipster);
    },

    async validaCodigoTipster(context, tipster) { 
      return await authService.validaCodigoTipster(tipster);
    },

    // USUARIOS
    async usuarios(context,usuario) {
      return await authService.usuarios(usuario);
    },

    async guardaUsuario(context, usuario) { 
      return await authService.guardaUsuario(usuario);
    },

    async borraUsuario(context, usuario) { 
      return await authService.borraUsuario(usuario);
    },

    async generarClaveUsuario(context, usuario) { 
      return await authService.generarClaveUsuario(usuario);
    },


    // PRODUCTOS
    async productos(context,producto) {
      return await authService.productos(producto);
    },

    async guardaProducto(context, producto) { 
      return await authService.guardaProducto(producto);
    },

    async borraProducto(context, producto) { 
      return await authService.borraProducto(producto);
    },

    async consultaProducto(context, uuid) { 
      return await authService.consultaProducto(uuid);
    },
 
    async validaEmailUsuario(context, email) { 
      return await authService.validaEmailUsuario(email);
    },

    // PEDIDOS
    async pedidos(context,pedido) {
      return await authService.pedidos(pedido);
    },

    // ESTADISTICAS
    async estadisticas() {
      return await authService.estadisticas();
    },

    // CONFIGURACION
    async configuracion(context,configuracion) {
      return await authService.configuracion(configuracion);
    },

    async guardaConfiguracion(context, configuracion) { 
      return await authService.guardaConfiguracion(configuracion);
    },

    // CANALES
    async canales(context,canal) {
      return await authService.canales(canal);
    },

    async guardaCanal(context, canal) { 
      return await authService.guardaCanal(canal);
    },

    async borraCanal(context, canal) { 
      return await authService.borraCanal(canal);
    },

    async listaCanales(context,tipster) {
      return await authService.listaCanales(tipster);
    },

    // CAMPANAS
    async campanas(context,campana) {
      return await authService.campanas(campana);
    },

    async guardaCampana(context, campana) { 
      return await authService.guardaCampana(campana);
    },

    async borraCampana(context, campana) { 
      return await authService.borraCampana(campana);
    },

    async grupoTelegram(context,canal) {
      return await authService.grupoTelegram(canal);
    },

  },
  mutations: {
    logMeIn(state) {
      state.loggedIn = true;
    },
    logMeOut(state) {
      state.loggedIn = false;
    }
  },

  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
  },
};
