import Swal from "sweetalert2";

export default {
  methods: {
    showSwal(options) {
      new Swal({
        toast: true,
        position: "top-right",
        iconColor: "white",
        width: options.width ? options.width : 300,
        text: options.message,
        customClass: {
          popup: options.type === "success" ? "bg-success" : "bg-danger",
          htmlContainer: "text-white",
        },
        showConfirmButton: false,
        showCloseButton: true,
        timer: options.timer ? options.timer : 6000,
        timerProgressBar: true,
      });
    },
    showMessage(options) {
      Swal.fire({
        title: options.title || "Título de la Ventana",
        text: options.message || "Este es el contenido de la ventana.",
        icon: options.type || null, // "success", "error", "warning", "info"
        width: options.width || 400,
        background: "#ffffff", // Fondo blanco
        color: "#000000", // Texto en negro
        confirmButtonText: options.confirmText || "Aceptar",
        showCloseButton: true,
        timer: options.timer || null,
        timerProgressBar: options.timer ? true : false,
      });
    }
  },
};
